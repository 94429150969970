import React from 'react';
import useHover from '@hzdg/use-hover';
import {useSpring, animated, config} from 'react-spring';
import {Link} from '@components/Link';
import {styled, Colors} from '@styles';

interface LogoButtonProps {
  alt: string;
  image: string;
  link: string;
}

const Container = styled(animated.div).withConfig({
  componentId: 'universityPartnerLogoButtonContainer'
})`
  height: 65px;
  width: 100%;
  padding: 0.5em;
  box-sizing: border-box;
  background: ${Colors.White};

  .wide & {
    height: 136px;
    padding: 2.5em;
  }
`;

const Image = styled(animated.div).withConfig({
  componentId: 'universityPartnerLogoButtonImage'
})<{image: string}>`
  background-image: ${({image}) => `url(${image})`};
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
`;

const UniversityPartnerLogoButton = ({
  alt,
  link,
  image,
}: LogoButtonProps): JSX.Element => {
  const [isHovering, hoverProps] = useHover();
  const {scale, boxShadow} = useSpring({
    config: config.stiff,
    scale: isHovering ? 1.05 : 1,
    boxShadow: isHovering
      ? '0 10px 53px 12px rgba(0, 0, 0, 0.15)'
      : '0 0px 0px 0px rgba(0, 0, 0, 0)',
  });

  return (
    <Link href={link} aria-label={alt} style={{zIndex: isHovering ? 1 : 0}}>
      <Container style={{boxShadow}} {...hoverProps}>
        <Image image={image} style={{scale}} />
      </Container>
    </Link>
  );
};

export default UniversityPartnerLogoButton;
