import React, {Fragment} from 'react';
import {graphql} from 'gatsby';

import Meta from '@components/Meta';
import CanonicalUrl from '@components/CanonicalUrl';
import Hero from '@components/Hero';
import {ResponsiveContainer, FontScale} from '@components/layout';
import {ThemeProvider, Colors, styled} from '@styles';
import usePartnerPrograms from '@util/usePartnerPrograms';
import {HeroSectionCallout} from '@components/SectionCallout';
import {UniversityPartnersGrid} from '@components/UniversityPartners';
import UniversityPartnerFilter, {
  FilterProvider,
} from '@components/UniversityPartnerFilter';
import {LatestSection, StickySection} from '@components/sections';
import {Block} from '@util/getDataFromBlockTypes';

interface Data {
  cms: CMS;
}
interface CMS {
  page: Page;
}

interface Page {
  searchDescription: string;
  seoTitle: string;
  hero: Block[];
}

const LatestStyledSection = styled(LatestSection).withConfig({
  componentId: 'partnersLatest'
})`
  background: ${Colors.TintedFogLight};
`;

export default function Partners({data}: {data: Data}): JSX.Element {
  const {hero, canonicalUrl} = data.cms.page;
  const {
    academicDisciplineOptions,
    academicDisciplineOptionsWithPartners,
    offeringTypeOptions,
  } = usePartnerPrograms();

  return (
    <Fragment>
      <Meta title="Partners" description="2U Partners" />
      {canonicalUrl && <CanonicalUrl canonicalUrl={canonicalUrl} />}
      <ThemeProvider theme={{fg: Colors.White, bg: Colors.Transparent}}>
        <ResponsiveContainer as={FontScale}>
          <ThemeProvider theme={{maxWidth: '600px'}}>
            <Hero heroSectionType={'medium'}>
              <HeroSectionCallout heroProps={hero} />
            </Hero>
          </ThemeProvider>
          <FilterProvider>
            <StickySection
              theme={{
                bg: Colors.Transparent,
                fg: Colors.Charcoal,
                maxPadding: '2em 0 6em',
              }}
              withOffset
              withPadding
            >
              {/* FIXME: Use Subnavigation for this, or otherwise ensure UX parity */}
              <UniversityPartnerFilter
                academicDisciplineOptions={
                  academicDisciplineOptionsWithPartners
                }
                offeringTypeOptions={offeringTypeOptions}
              />
            </StickySection>
            <UniversityPartnersGrid />
          </FilterProvider>
          <LatestStyledSection />
        </ResponsiveContainer>
      </ThemeProvider>
    </Fragment>
  );
}

export const query = graphql`
  fragment PartnersFragment on CMS_PartnerIndexPage {
    seoTitle
    searchDescription
    canonicalUrl
    hero {
      __typename
      ...headerBlockFragment
    }
  }
  query Partners {
    cms {
      page(url: "/home/partners/") {
        ...PartnersFragment
      }
    }
  }
`;
